import React from 'react';
import Headline from "../../../components/HeadLine/HeadLine";
import Proprietarios from "./Proprietarios";
import InquilinosCompradores from "./InquilinosCompradores";
import Corretores from "./Corretores";

const Vantagens = () => {
    return (
        <section>
            <div className="vantagens">
                <Headline label="Vantagens para nossos clientes" />
                <div className="container">
                    <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="active">
                            <a href="#inquilinos-compradores" aria-controls="inquilinos-compradores" role="tab"
                               data-toggle="tab">Inquilinos ou compradores</a>
                        </li>
                        <li role="presentation">
                            <a href="#proprietarios" aria-controls="proprietarios" role="tab"
                               data-toggle="tab">Proprietários</a>
                        </li>
                        <li role="presentation">
                            <a href="#corretores" aria-controls="corretores" role="tab"
                               data-toggle="tab">Corretores</a>
                        </li>
                    </ul>
                    <div className="tab-content">

                        <InquilinosCompradores/>
                        <Proprietarios/>
                        <Corretores/>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Vantagens;