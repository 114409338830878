
import React from 'react';

const modalContato = (props) => {
    return (
        <div className={`modal fade ${props.modalName}`} id={props.modalName} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title" id="myModalLabel">Nossos contatos via WhatsApp</h4>
                    </div>
                    <div className="modal-body">
                        <p>Clique em um de nossos contatos para iniciar uma conversa com um de nossos colaboradores.</p>
                        <div className="row">
                            <div className="col-md-6 col-sm-6" style={{ borderRight: '1px solid #ccc'}}>                                
                                <h2>Departamento de <span>Venda</span></h2>
                                <h3>
                                    <a href="https://api.whatsapp.com/send?phone=5585987341921&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20César%20Rêgo%20Im%C3%B3veis" target="_blank" rel="noopener noreferrer" >
                                        <i className="fa fa-whatsapp"></i> +55 85 98734-1921
                                    </a>
                                </h3>
                                {/* <h3>
                                    <a href="https://api.whatsapp.com/send?phone=5585981515553&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20César%20Rêgo%20Im%C3%B3veis" target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-whatsapp"></i> +55 85 98151-5553
                                    </a>
                                </h3> */}
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <h2>Departamento de <span>Aluguel</span></h2>
                                <h3>
                                    <a href="https://api.whatsapp.com/send?phone=5585991619657&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20César%20Rêgo%20Im%C3%B3veis" target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-whatsapp"></i> +55 85 99161-9657
                                    </a>
                                </h3>
                                <h3>
                                    <a href="https://api.whatsapp.com/send?phone=55859981574155&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20César%20Rêgo%20Im%C3%B3veis" target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-whatsapp"></i> +55 85 98157-4155
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default modalContato;