import React from 'react';
import Features from './../../../components/Card/Features';

const Icons = (props) => {
    return (
        <div className="detail-icons">
            
            <Features 
                preco = {props.data.valor}
                area = {(props.data.area_util === 0 ? props.data.area_total : props.data.area_util)}
                quartos = {props.data.quartos}
                suites = {props.data.suites}
                banheiros = {props.data.banheiros}
                garagem = {props.data.vagas}
                codigo = {props.data.id}
            />

            {/* <h2 className="panel-hint">
                <i className="fa fa-info-circle"></i>
                Ninguém esquece esse {props.data.tipo}! Ele recebeu {props.data.views} visualizações em {props.data.periodo} dias.
            </h2> */}
        </div>
    );
}
export default Icons;