import React, {Component} from 'react';
import { Link } from "react-router-dom";
import './error404.css';

class Error404 extends Component {
    render() {
        return (
            <section className='main'>
                <div className="container">
                    <div className="error404">
                        <p>Error 404</p>
                        <h1>
                            Página não encontrada
                        </h1>                        
                        <h2>
                            Não encontramos a página que você está procurando. &nbsp;
                            Pode ser que você tenha digitado o endereço da página incorretamente.
                        </h2>
                        
                        <Link to={'/'}>                        
                            Clique aqui para ir para nossa página inicial &nbsp;
                            <i className="fa fa-rotate-left"></i>
                        </Link>

                        <div className="secao-imoveis">  
                            <h2>Ou se preferir:</h2>  

                            <Link to={`/busca/venda/apartamento/fortaleza`} className="btn-secao-imoveis">                        
                                Ir para seção de Vendas
                            </Link>

                            <Link to={`/busca/aluguel/apartamento/fortaleza`} className="btn-secao-imoveis">                        
                                Ir para seção de Aluguel
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Error404;