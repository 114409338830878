import BaseServices from './../../../helpers/BaseServices';
import { MODULE_ENDPOINT } from "./constants";

export default class Services extends BaseServices {
  constructor() {
    super(MODULE_ENDPOINT);
  };

  getTiposImoveisOptions = (finalidade) => {
    return fetch(`${MODULE_ENDPOINT}/${finalidade}/tiposImoveisOptions`, BaseServices._getRequestInfo())
      .then(response => {
        if (!response.ok) {
          throw response.json();
        }

        return response.json();
      });
  }

  getbuscaEndereco = (finalidade, palavra) => {
    return fetch(`${MODULE_ENDPOINT}/${finalidade}/buscaEndereco?palavra=${palavra}`, BaseServices._getRequestInfo())
      .then(response => {
        if (!response.ok) {
          throw response.json();
        }

        return response.json();
      });
  }
}