import Util from './Util';

class Favoritos {
    constructor() {
        let favoritosLocal = localStorage.getItem('favoritos');
        if (favoritosLocal === null) {
            localStorage.setItem('favoritos', JSON.stringify([]));
        }
    }

    addIcon(id) {
        let element = document.getElementById(`icon_${id}`);
        element.classList.add('fa-star');
        element.classList.remove('fa-star-o');  
    }

    removeIcon(id) {
        let element = document.getElementById(`icon_${id}`);
        element.classList.add('fa-star-o');
        element.classList.remove('fa-star');           
    }

    deleteCardFavorito(id) {
        let element = document.getElementById(`icon_${id}`);        
        if (element.getAttribute("class") === 'fa fa-trash') {
            let card = element.parentNode.parentNode.parentNode;
            Util.fadeOut(card.getAttribute('id'), 0.5);
            setTimeout(function(){ 
                card.style.display = 'none'
            }, 500);
        } else {
           this.removeIcon(id);
        }      
    }       

    adicionarRemoverFavoritos = (item) => {        
        let totalfavoritos = this.getQuantidadeFavoritos();
        let totalizadorFavorito = document.getElementById('totalizadorFavorito');

        let favoritosLocal = localStorage.getItem('favoritos');
        let favoritos = [];
        let existe = [];
        if (JSON.parse(favoritosLocal).length === 0) {
            localStorage.setItem('favoritos', JSON.stringify([item]));           
            this.addIcon(item.id);          
            totalizadorFavorito.innerHTML = totalfavoritos + 1;
        } else {
            favoritos = JSON.parse(favoritosLocal);
            existe = favoritos.filter(row => (row.id === item.id));
            if (existe.length === 0) {
                favoritos.push(item);               
                localStorage.setItem('favoritos', JSON.stringify(favoritos));
                this.addIcon(item.id);
                totalizadorFavorito.innerHTML = totalfavoritos + 1;              
            } else {
                let excecao = favoritos.filter(row => (row.id !== item.id));
                localStorage.setItem('favoritos', JSON.stringify(excecao));
                this.deleteCardFavorito(item.id);
                totalizadorFavorito.innerHTML = totalfavoritos - 1; 
            }
        }
    };

    verificarFavorito = (id, icone) => {
        let favoritosLocal = localStorage.getItem('favoritos');
        let favoritos = [];
        let existe = [];
        if (JSON.parse(favoritosLocal).length === 0) {           
            return `fa fa-${icone}-o`;
        }
        favoritos = JSON.parse(favoritosLocal);
        existe = favoritos.filter(row => (row.id === id));
        if (existe.length > 0) {
            return `fa fa-${icone}`;
        } else {
            return `fa fa-${icone}-o`;
        }
    }

    getFavoritos = () => {
        return JSON.parse(localStorage.getItem('favoritos'));
    }

    getQuantidadeFavoritos = () => {
        return JSON.parse(localStorage.getItem('favoritos')).length;
    }
}

export default Favoritos;