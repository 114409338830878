import React from 'react';
import ImoveisBairroEdificio from './../../../components/Card/CardImoveisBairroEdificio/ImoveisBairroEdificio';

const ImoveisDaRegiao = (props) => {
    return (
        <section>
            <div className="imoveis-da-regiao">
                <div className="container">                            
                    <ImoveisBairroEdificio data={props.venda}/>
                    <ImoveisBairroEdificio data={props.aluguel}/>                            
                </div>
            </div>
        </section>
    );
}
export default ImoveisDaRegiao;