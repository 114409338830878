import BaseServices from '../../helpers/BaseServices';
import { MODULE_ENDPOINT } from "./constants";

export default class Services extends BaseServices {
    constructor() {
    super(MODULE_ENDPOINT);
    };

    busca = (finalidade, tipo, endereco, filtros = [], page, order = 'menor-valor', limit = 10, offset = 0, caracSelecionados = [], caracSelecionadosCondominio = []) => {

        let currentPage = (page ? page : 1);

        let url = `${MODULE_ENDPOINT}/${finalidade}/imoveis?limit=${limit}&offset=${offset}page=${currentPage}&tipo=${tipo.replace(/-/g, ' ')}&endereco=${endereco}`;

        if (filtros) {
            for (let i in filtros) {
                if (filtros[i] === '' || filtros[i] === null || filtros[i] === undefined) continue;
                url += `&${i}=${filtros[i]}`;
            }
        }

        if (caracSelecionados.length > 0) {
            let list = caracSelecionados.map(item => [
                item.value
            ]);
            url += `&caracteristicas=${list}`;
        }
        
        if (caracSelecionadosCondominio.length > 0) {   
            let list = caracSelecionadosCondominio.map(item => [
                item.value
            ]);
            url += `&caracsCondominio=${list}`;
        }

        return fetch(url, BaseServices._getRequestInfo())
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    };

    getCaracteristicasImovel = (finalidade, tipo) => {
        return fetch(`${MODULE_ENDPOINT}/${finalidade}/caracteristicasOptions?tipo=${tipo}`, BaseServices._getRequestInfo())
            .then(response => {
            if (!response.ok) {
                throw response.json();
        }    
            return response.json();
        });
    }

    getCaracteristicasCondominio = (finalidade, tipo) => {
        return fetch(`${MODULE_ENDPOINT}/${finalidade}/caracteristicasCondominioOptions?tipo=${tipo}`, BaseServices._getRequestInfo())
            .then(response => {
            if (!response.ok) {
                throw response.json();
        }    
            return response.json();
        });
    }

    solicitarInformacoesImovel = (dados) => {
        return fetch(`${MODULE_ENDPOINT}/email/solicitarInformacoesImovel`, BaseServices._getRequestInfo('POST', `${JSON.stringify(dados)}`))    
          .then(response => {
            if (!response.ok) {
              throw response.json();          
            }
    
            return response.json();
        });
    }
}