import React, { Component } from 'react';
import Util from './../../../helpers/Util';

class Title extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tituloDetalhes: '',
            modalidade: '',
            tipo: '',        
            bairro: '',
            cidade: '',
            estado: '',
            slug: ''
        };
    }    

    static getDerivedStateFromProps(nextProps) {               
        if (nextProps.tipo !== undefined) {
            return {          
                tituloDetalhes: nextProps.tituloDetalhes,      
                modalidade: nextProps.modalidade.toLowerCase(),
                tipo: nextProps.tipo.toLowerCase(),
                bairro: nextProps.bairro.toLowerCase(),
                cidade: nextProps.cidade.toLowerCase(),
                estado: nextProps.estado.toLowerCase(),
                slug: nextProps.slug
            }
        }
        return null;
    }

    returnSearch(){
        let link='';
        if (document['referrer'] && this.state.slug) { 
            link = document['referrer']
        } else {
            link = `/busca/${this.state.modalidade}/${Util.transliterate(this.state.tipo)}/${Util.transliterate(this.state.cidade)}`
        }

        return <a href={link}><i className="fa fa-arrow-left"></i> Retornar</a>
    }

    render(){
        return (
            <div className="header-detail">
                <h1>
                    {this.state.tituloDetalhes}
                    <address>
                        <i className="fa fa-map-marker"></i> {this.state.bairro}, &nbsp;
                        {Util.capitalize(this.state.cidade)} - &nbsp;
                        {Util.capitalize(this.state.estado)}
                    </address>
                </h1>
               {this.returnSearch()}
            </div>
        );
    }
}
export default Title;