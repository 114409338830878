import React from 'react';
import HeadLine from './../../../components/HeadLine/HeadLine';
import Botao from './../../../components/Botao/Botao';
import './vantagens.css';
import Item from './Item';

const Content = () => {
    return (
        <section>
            <div className="vantagens-corretor">

                <HeadLine label="Conheça as vantagens de ser um corretor associado à César Rêgo"/>  
                        
                <div className="container">
                    <Item icon="dinheiro-na-mao" title="Ganhe dinheiro com vendas, captação e aluguel">
                        Você tem a oportunidade de ganhar com venda e com aluguel de imóveis. Faça parte desse sucesso!
                    </Item>
                    <Item icon="clientes" title="Trabalhe com a maior carteira de imóveis e clientes de Fortaleza">
                        Como corretor associado à César Rêgo, você tem acesso ao maior portfólio de imóveis de Fortaleza 
                        e a um grande fluxo de clientes interessados. Aumentando as suas chances de gerar e fechar mais 
                        negócios.
                    </Item>
                    <Item icon="mobile" title="Constante investimento em tecnologia">
                        Investimos em tecnologias que facilitam o dia-a-dia do corretor e que atraiam mais clientes qualificados. 
                        Oferecemos as melhores ferramentas para você se concentrar em fazer com excelência o seu trabalho.
                    </Item>
                    <Item icon="clientes2" title="Temos muitos clientes a seu dispor">
                        Priorizamos investimentos em publicidade. Assim conseguimos oferecer aos nossos corretores 
                        associados um extenso volume de clientes para serem trabalhados.
                    </Item>
                    <Item icon="grafico" title="Remuneração verdadeiramente sem limites">
                        O potencial de negócios é muito alto. Com possibilidades de ganhos na indicação para a locação e 
                        captação para a venda.
                    </Item>
                    <Item icon="pasta" title="Trabalho verdadeiramente prazeroso">
                        Se você tem prazer em trabalhar com pessoas e não deseja ficar restrito a um escritório o dia inteiro, seu 
                        lugar é aqui. Você vai adorar o clima leve e de cooperação entre os corretores associados e se
                        surpreender com as relações que irá estabelecer com seus clientes.
                    </Item>
                    
                    <div className="realizar-cadastro">
                        <Botao anchor={true} to="realizar-cadastro" label="Realizar cadastro" />
                    </div>    
                </div>
            </div>    
        </section>
    );
}
export default Content;