import React, {Component} from 'react';
import { Link } from "react-router-dom";
import './favoritos.css';

class NoFavorite extends Component {
    render() {
        return (
            <div className="no-favorite">
                <div className='title'>
                    <h1>Oops! Você ainda não favoritou imóveis</h1> 
                    <p>Veja abaixo como adicionar um imóvel aos favoritos</p>
                </div>

                <div className='wrap'>
                    <div className='col-img'>
                    </div>                      
                    <div className='col-text'>
                        <h2>
                            Na página de busca por imóveis <br />
                            Clique na <span>estrela</span> à direita do título do imóvel.
                        </h2>
                    </div>
                </div>

                <div className="secao-imoveis">
                    <h2>O que deseja?</h2>  
                    <Link to={`/busca/venda/apartamento/fortaleza`} className="btn-secao-imoveis">                        
                        Ir para seção de Vendas
                    </Link>

                    <Link to={`/busca/aluguel/apartamento/fortaleza`} className="btn-secao-imoveis">                        
                        Ir para seção de Aluguel
                    </Link>
                </div>                          
            </div>
        );
    }
}
export default NoFavorite;