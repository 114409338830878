import React from 'react';
import {Link} from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <div className="quem-somos">
                <div className="container">
                    <div className="overlay"></div>            
                    <div className="wrap">                
                        <div className="logomarca">
                            <Link to="/">
                                <img src="images/logomarca/logomarca-horizontal.png" alt="Césa Rêgo Imóveis" />
                            </Link>    
                        </div>
                        <div className="text">
                            <p>
                                A atuação da <span>César Rêgo</span> no mercado imobiliário começou há mais de 30 anos. Porque, antes de ser Razão 
                                Social, <span>César Rêgo</span> já era o nome de um construtor. Que começou justamente do outro lado do balcão, 
                                trabalhando em parceria com imobiliárias.
                            </p>
                            <p>
                                Esse histórico explica um pouco o nosso posicionamento hoje. Por conhecermos todos os lados do mercado
                                imobiliário, desenvolvemos uma capacidade ímpar de oferecer serviços personalizados e com maior
                                eficiência e profissionalismo. 
                            </p>
                        </div>                    
                        <div className="text">
                            <h2>Porque vamos além.</h2>
                            <p>
                                Conhecemos o lado de quem constrói. E, ao lado do construtor, acompanhamos todas as etapas de um
                                projeto, da visita ao terreno à escolha do acabamento. Conhecemos o lado de quem compra ou aluga.
                                Por isso, ao lado do cliente, podemos indicar o imóvel perfeito para fazer um investimento ou realizar
                                um sonho.  
                            </p>
                            <p>
                                Oferecendo serviços de venda e locação no Ceará, com uma carteira que abrange tanto produtos econômicos
                                como empreendimentos de alto padrão, a <span>César Rêgo</span> tem compromisso permanente com a 
                                satisfação dos nossos clientes.
                            </p>
                        </div>
                    </div>    
                </div>
            </div>    
        </header>
    );
}
export default Header;