import React from 'react';
import Util from './../../../helpers/Util';
import './precoMedio.css';

const PrecoMedio = (props) => {
    return (
        <div className="preco-medio-content">
            <div className="card-list">
                <h2>
                    Preço médio do apartamento anunciado para venda
                    <span>{Util.currencyFormat(props.data.precoMedio)}</span>
                </h2>
                <h3>amostra = 2882 apartamentos</h3>
                <ul>
                    <li><strong>Primeiro quartil</strong> {Util.currencyFormat(props.data.quartil1)}</li>
                    <li><strong>Mediana</strong> {Util.currencyFormat(props.data.mediana)}</li>
                    <li><strong>Terceiro quartil</strong> {Util.currencyFormat(props.data.quartil3)}</li>
                </ul>
            </div>
        </div>
    );
}
export default PrecoMedio;