import React from 'react';
import './listFeatures.css';
import Utils from "../../helpers/Util";

const ListFeatures = (props) => {
    return (
        <div>
            <div className="detail-title">
                <h2>{props.title}</h2>
            </div>
            <div className="detail-list">
                <ul>
                    {props.list.map((carac, index) => (
                         <li key={index}>
                            <i className="fa fa-check-square-o"></i>
                            {Utils.capitalize(carac.nome)}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
export default ListFeatures;