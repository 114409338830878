import React, { Component } from 'react';
import {Form, FormGroup, Input, Button} from "reactstrap";
import MaskedInput from 'react-text-mask';
import './contato.css';
import Services from './Services';

class Contato extends Component {

    constructor(props){
        super(props);
        this._services = new Services(); 
        this.state = {
            departamento: '',
            nome: '',
            email: '',
            textMail: '',
            telefone: ''
        };
    }    

    openForm = () =>{       
        const element = document.querySelector('#content-fluid-whatsapp');
        element.classList.add('open-form');
    };

    closeForm = () =>{
        const element = document.querySelector('#content-fluid-whatsapp');
        this.clearInputs();
        element.classList.remove('open-form');
    }

    clearInputs = () => {
        const departamento = document.querySelector('#form-fluid-departamento');
        const nome = document.querySelector('#form-fluid-nome');
        const email = document.querySelector('#form-fluid-email');
        const telefone = document.querySelector('#form-fluid-telefone');

        departamento.value = '';
        nome.value = '';
        email.value = '';
        telefone.value = '';
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClick = (e) => {
        e.preventDefault();

        // let phoneRent = Math.floor(Math.random() * 2) === 0 ? '5585991619657' : '5585981574155';

        // window.open(`https://api.whatsapp.com/send?phone=${this.state.departamento === 'venda' ? '5585987341921' : phoneRent }&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20César%20Rêgo%20Im%C3%B3veis`);
        
        window.open('https://api.whatsapp.com/send/?phone=558533053000&text&app_absent=0');

        let dados = 
        {   
            "departamento": this.state.departamento,
            "nome": this.state.nome,
            "email": (this.state.departamento === 'venda' ? this.state.email : this.state.textMail),
            "telefone": this.state.telefone
        }      
        
        this._services.contato(dados)
            .then(() => document.InteresseImovelWhats.submit());

    }

    handleOnChangeFinalidade = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')
        });
    };


    render() {
        return (
            <div className="fluid-whatsapp" id="content-fluid-whatsapp">                
                <img src="/images/icons/icon-whatsapp2.png" alt="Logomarca César Rêgo imóveis" onClick={this.openForm} width="100%" height="auto" />
                <div className='content-form-fluid'>
                    <i className="fa fa-times" onClick={this.closeForm}></i>
                    <Form onSubmit={(e) => this.handleClick(e)} name="InteresseImovelWhats">
                        <FormGroup>                            
                            <Input
                                id="form-fluid-departamento" 
                                type="select"
                                name="departamento"
                                onChange={(e) => this.handleOnChangeFinalidade(e)}
                                required
                            >
                                <option value="">Selecione o departamento</option>
                                <option value="venda">Dep. de Venda</option>
                                <option value="aluguel">Dep. de Locação</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                id="form-fluid-nome" 
                                name="nome" 
                                onChange={e => this.handleOnChange(e)} 
                                type="text" 
                                placeholder="Seu nome" 
                                required 
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                id='form-fluid-email' 
                                name={this.state.departamento === 'venda' ? 'email' : 'textMail'}  
                                onChange={e => this.handleOnChange(e)} 
                                type={this.state.departamento === 'venda' ? 'email' : 'text'}
                                placeholder="Seu e-mail" 
                                required
                            />
                        </FormGroup>
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control"
                            placeholder="Seu telefone"                            
                            guide={false}
                            id="form-fluid-telefone"
                            name='telefone'
                            onChange={e => this.handleOnChange(e)}
                            required={true} />

                        <Button type="submit" className="btn botao"><i className="fa fa-whatsapp"></i> Iniciar conversa</Button>                    
                    </Form>
                </div>
            </div>
        );
    }
}

export default Contato;