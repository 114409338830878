import React, {Component} from 'react';
import BuscaContent from './Busca/BuscaContent';
import Artigos from './ArtigosBlog';
import ImoveisDestaques from "./ImoveisDestaques/ImoveisDestaques";
import ImoveisMaisPopulares from "./Populares/ImoveisMaisPopulares";
import Empreendimentos from "./Empreendimentos";
import Vantagens from "./Vantagens/Vantagens";
import ScrollIntoView from './../../components/scrollIntoView';
import './home.css';

class Home extends Component {
    render() {
        return(
            <ScrollIntoView>                
                <main>
                    <BuscaContent {...this.props}/>                    
                    <ImoveisDestaques/>
                    <ImoveisMaisPopulares/>
                    <Empreendimentos/>
                    <Vantagens/>
                    <Artigos></Artigos>
                    
                </main>
            </ScrollIntoView>
        );
    }
}
export default Home;