import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from './../../../helpers/Util';

const cards = [    {   
        image: '/images/icons/icon-apartamento.png',
        tipo: 'Apartamento',
        cidade: 'Fortaleza',
        bairrosVenda: ['Aldeota', 'Cocó', 'Meireles', 'Guararapes'],
        bairrosAluguel: ['Aldeota', 'Centro', 'Meireles', 'Messejana'],
        slug_tipo: 'apartamento',
        slug_cidade: 'fortaleza',
    },
    {   
        image: '/images/icons/icon-casa.png',
        tipo: 'Casa',
        cidade: 'Fortaleza',
        bairrosVenda: ['Aldeota', 'Cocó', 'Meireles', 'Guararapes'],
        bairrosAluguel: ['Aldeota', 'Centro', 'Meireles', 'Messejana'],
        slug_tipo: 'casa',
        slug_cidade: 'fortaleza',
    },
    {  
        image: '/images/icons/icon-loja.png',
        tipo: 'Loja',
        cidade: 'Fortaleza',
        bairrosVenda: ['Aldeota', 'Cocó', 'Meireles', 'Guararapes'],
        bairrosAluguel: ['Aldeota', 'Centro', 'Meireles', 'Messejana'],
        slug_tipo: 'loja',
        slug_cidade: 'fortaleza',
    },
    { 
        image: '/images/icons/icon-sala.png',
        tipo: 'Sala',
        cidade: 'Fortaleza',
        bairrosVenda: ['Aldeota', 'Cocó', 'Meireles', 'Guararapes'],
        bairrosAluguel: ['Aldeota', 'Centro', 'Meireles', 'Messejana'],
        slug_tipo: 'sala',
        slug_cidade: 'fortaleza',
    }
]

class CardDestaque extends Component {

    state = {
        cards: []
    }

    componentDidMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.setState({cards: cards})
    }

    render() {
        return(
            <div className="row">
                {this.state.cards.map((card, index) => (
                    <div key={index} className="col">
                        <div className="card">
                            <div className="image-card">
                                <img src={card.image} alt={card.tipo} />
                            </div>
                            <div className="title-card">
                                <h3>{card.tipo}</h3>
                                <h4>
                                    <Link to={`/busca/venda/${card.slug_tipo}/${card.slug_cidade}`}>
                                        <span>Compre</span> imóveis em {card.cidade}
                                    </Link>
                                </h4>
                            </div>
                            <div className="link-card">
                                <ul>
                                    {card.bairrosVenda.map(bairro => (
                                        <li key={bairro}>
                                            <Link to={'/busca/venda/' + card.slug_tipo + '/'+ Util.transliterate(bairro)}>
                                                {bairro}
                                            </Link>
                                        </li>
                                    ))}
                                    <li className="tag">
                                        <Link to={`/busca/venda/${card.slug_tipo}/${card.slug_cidade}`}>
                                            Todos os bairros
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="title-card">
                                <h4>
                                    <Link to={`/busca/aluguel/${card.slug_tipo}/${card.slug_cidade}`}>
                                        <span>Alugue</span> imóveis em {card.cidade}
                                    </Link>
                                </h4>
                            </div>
                            <div className="link-card">
                                <ul>
                                    {card.bairrosAluguel.map(bairro => (
                                        <li key={bairro}>
                                            <Link to={'/busca/aluguel/' + card.slug_tipo + '/'+ Util.transliterate(bairro)}>
                                                {bairro}
                                            </Link>
                                        </li>
                                    ))}
                                    <li className="tag">
                                        <Link to={`/busca/aluguel/${card.slug_tipo}/${card.slug_cidade}`}>
                                            Todos os bairros
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
export default CardDestaque;