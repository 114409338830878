import React, { Component } from 'react';
import Headline from "../../components/HeadLine/HeadLine";
import CardImovel from "../../components/Card/CardImovel";
import NoFavorite from './NoFavorite';
import FavoritosHelper from "../../helpers/Favoritos";
import './favoritos.css';

class Favoritos extends Component {
    constructor (props) {
        super(props);
        this._favoritos = new FavoritosHelper();        
    }

    render() {
        let content;
        if (this._favoritos.getQuantidadeFavoritos() > 0) {
            content = 
            <div>
                <Headline label="Meus imóveis favoritos" /> 
                <CardImovel cards={this._favoritos.getFavoritos()} icon="trash" />
            </div>
        } else {
            content = <NoFavorite/>;
        }
        return (
            <main>
                <div className="favoritos">
                    <div className="container">
                        
                        {content}
                    </div>
                </div>
            </main>
        );
    }
}

export default Favoritos;