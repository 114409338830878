import React from 'react';
import Botao from './../../components/Botao/Botao';

const Servicos = () => {
    return (
        <section>
            <div className="servicos">
                <div className="headline">
                    <h2>Está procurando um imóvel ou quer anunciar o seu com a gente?</h2>
                    <div className="container">
                        <h3>
                            A César Rêgo Imóveis atua no mercado da capital cearense há mais de 30 anos, sempre 
                            acompanhando as movimentações e negociações, para lhe ajudar.
                        </h3>
                        <div className="col">
                            <Botao to="/busca/venda/apartamento/fortaleza" label="Compre com a César Rêgo" />
                        </div>
                        <div className="col">
                            <Botao to="/busca/aluguel/apartamento/fortaleza" label="Alugue com a César Rêgo" />                            
                        </div>
                        <div className="col">
                            <Botao to="/cadastre_seu_imovel" label="Anuncie com a César Rêgo" />
                        </div>
                        <h3 className="contato">Ou ligue <span>(85) 3305-3000</span></h3>
                    </div>    
                </div>
            </div>
        </section>
    );
}
export default Servicos;