import React from 'react';
import Header from './Header';
import Negocios from './Negocios';
import Depoimentos from './Depoimentos';
import Servicos from './Servicos';
import Footer from './../../components/Layout/Footer/Footer';
import ScrollIntoView from './../../components/scrollIntoView';
import './quemSomos.css';

const QuemSomos = () => {
    return (
        <ScrollIntoView>
            <div>
                <Header/>            
                <main>
                    <Negocios/>
                    <Depoimentos/>
                    <Servicos/>
                </main>
                <Footer/>
            </div>
        </ScrollIntoView>
    )
}
export default QuemSomos;