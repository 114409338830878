import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Formulario from './Formulario';

class Header extends Component {
    render() {
        return (
            <header>
                <div className="header">
                    <div className="container">
                        <div className="overlay"></div>           
                        <div className="wrap">                
                            <div className="logomarca">
                                <Link to="/">
                                    <img src="images/logomarca/logomarca-horizontal.png" alt="Césa Rêgo Imóveis"/>
                                </Link>    
                            </div>
                            <div className="col">
                                <h1>
                                    Seja um corretor <span>César Rêgo</span> e tenha acesso as melhores <span>oportunidades de negócios</span> em Fortaleza.
                                </h1>
                                <ul>
                                    <li><i className="fa fa-check"></i> Trabalhe com a maior carteira de imóveis e clientes de Fortaleza</li>
                                    <li><i className="fa fa-check"></i> Faça parte do seleto grupo que participa dos melhores plantões de Fortaleza</li>
                                    <li><i className="fa fa-check"></i> Mobilidade de acessar clientes e imóveis pelo celular</li>
                                    <li><i className="fa fa-check"></i> Ganhe dinheiro com vendas, captação e aluguel</li>
                                    <li><i className="fa fa-check"></i> Receba as visitas marcadas em seu celular</li>
                                </ul>
                                <p>
                                    A César Rêgo possui a tradição de uma empresa com mais de 30 anos no mercado. Desenvolvemos
                                    uma capacidade ímpar de oferecer serviços personalizados e com maior eficiência e 
                                    profissionalismo. Possuimos uma estrutura completa que irá facilitar as suas vendas. 
                                </p>
                                <p>
                                    Faça parte desse sucesso. Venha para a área nobre do mercado imobiliário. Cadastre-se agora.
                                </p>
                            </div>
                            <Formulario/>
                        </div>    
                    </div>
                </div>    
            </header>
        );
    }
}
export default Header;