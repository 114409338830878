import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Breadcrumb from "reactstrap/es/Breadcrumb";
import BreadcrumbItem from "reactstrap/es/BreadcrumbItem";
import Safearea from './Safearea';
import ImoveisDaRegiao from './ImoveisDaRegiao';
import PrecoMedio from './PrecoMedio';
import Ajuda from './Ajuda';
import Graficos from './Graficos';
import Finalidades from './Finalidades';
import Contato from './../../../components/Form/Contato';
import './detalhes.css';

const api = {
    bairro: 'Benfica',        
    cidade: 'Fortaleza',
    uf: 'CE',
    totalImoveis: 500
};
const apiImoveisVenda = {
    finalidade: 'venda',
    bairro: 'Benfica',        
    totalImovel: 600,
    precoMedio: 500000,
    quartil1:  370000,
    mediana: 370000,
    quartil3: 370000,     
    imoveis: [
        {
            tipo: 'apartamentos',
            total: 100,
            slug: '/venda/apatamento/benfica_fortaleza_ce'
        },
        {
            tipo: 'casas',
            total: 100,
            slug: '/venda/casa/benfica_fortaleza_ce'
        },
        {
            tipo: 'lojas',
            total: 100,
            slug: '/venda/loja/benfica_fortaleza_ce'
        },
        {
            tipo: 'salas',
            total: 100,
            slug: '/venda/sala/benfica_fortaleza_ce'
        },
        {
            tipo: 'terrenos',
            total: 100,
            slug: '/venda/terreno/benfica_fortaleza_ce'
        },
        {
            tipo: 'galpões',
            total: 100,
            slug: '/venda/galpao/benfica_fortaleza_ce'
        }
    ]
};
const apiImoveisAluguel = {
    finalidade: 'aluguel',
    bairro: 'Benfica',        
    totalImovel: 400,
    precoMedio: 3000,
    quartil1:  1700,
    mediana: 1700,
    quartil3: 1700,      
    imoveis: [
        {
            tipo: 'apartamentos',
            total: 100,
            slug: '/aluguel/apatamento/benfica_fortaleza_ce'
        },
        {
            tipo: 'casas',
            total: 100,
            slug: '/aluguel/casa/benfica_fortaleza_ce'
        },
        {
            tipo: 'lojas',
            total: 100,
            slug: '/aluguel/loja/benfica_fortaleza_ce'
        },
        {
            tipo: 'salas',
            total: 100,
            slug: '/aluguel/sala/benfica_fortaleza_ce'
        }
    ]
};

class DetalhesDoBairro extends Component {

    state = {
        data:{},
        venda: {}, 
        aluguel: {}
    }

    componentWillMount() {
        this.loadData()
    }

    loadData = () => {
        this.setState({
            data: api,
            venda: apiImoveisVenda,
            aluguel: apiImoveisAluguel
        })
    }

    render() {
        return (
            <main> 
                <div className="topo-breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/">César Rêgo</Link></BreadcrumbItem>
                            <BreadcrumbItem><Link to="/bairro">bairros</Link></BreadcrumbItem>
                            <BreadcrumbItem><Link to="/bairro/fortaleza_ce">Fortaleza - CE</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Benfica</BreadcrumbItem>
                        </Breadcrumb>
                    </div>    
                </div>     
                
                <Safearea data={this.state.data}/>
                <ImoveisDaRegiao venda={this.state.venda} aluguel={this.state.aluguel}/>
                <PrecoMedio bairro={this.state.data.bairro} venda={this.state.venda} aluguel={this.state.aluguel}/>
                <Ajuda bairro={this.state.data.bairro}/>
                <Graficos  bairro={this.state.data.bairro} venda={this.state.venda} aluguel={this.state.aluguel} />
                <Finalidades bairro={this.state.data.bairro}/>
                <Contato bairro={this.state.data.bairro}/>
                
            </main> 
        );
    }
}
export default DetalhesDoBairro;