import React from 'react';
import './card.css';

const Card = (props) => {
    return (
        <div className="col">                        
            <img src={`/images/icons/icon-${props.icon}.png`} alt={`Ícone ${props.icon}`} />
            <h2>{props.title}</h2>                  
            <h3>
                {props.children}
            </h3>
        </div>
    );
}
export default Card;