import React from 'react';
import './card-vantagens.css';

const CardVantagens = (props) => {
    return (
        <div className="vantagens-col">
            <div className="card">
                <img src={`/images/icons/icon-${props.icon}.png`} alt={props.icon}/>
                <h2>{props.children}</h2>
            </div>    
        </div>
    );
}
export default CardVantagens;