import React from 'react';

const MapLocation = (props) => {
    return (
        <iframe 
            src={"https://maps.google.com/maps?q="+props.endereco+"-"+props.cidade+"-"+props.uf+"&spn=0.043344,0.063343&t=M&hl=pt&output=embed"}
            width="100%"
            height={props.heigthDefault}
            style={{border:'0'}}  
            title="localizacao"                    
        >
        </iframe>
    );
}
export default MapLocation;