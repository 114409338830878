import React from 'react';

const Item = (props) => {
    return (
        <div className="col">
            <img src={`/images/icons/icon-${props.icon}.png`} alt={props.icon}/>
            <h2>{props.title}</h2>
            <h3>
                {props.children}
            </h3>
        </div>
    );
}
export default Item;