import React, {Component} from 'react';
import Highlight from "./../../../components/Card/Highlight";
import Carousel from "./../../../components/Card/Carousel";

import Icons from './Icons';
import { Segment } from "semantic-ui-react";

class Caracteristicas extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>    
                <div className="detail-media">
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane fade in active" id="fotos-imovel">
                            <div className="img-label-destaque">
                                <Highlight destaque={this.props.data.destaque} />
                                <Segment loading={(this.props.data.imagens.length === 0)} basic>
                                    <Carousel tour={this.props.data.tour} noLink={true} fotos={this.props.data.imagens} codigo={this.props.data.id} status_comercial={this.props.data.status_comercial} />
                                </Segment>
                            </div>
                        </div>                  
                    </div>
                    <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="active">
                            <a href="#fotos-imovel" aria-controls="fotos-imovel" role="tab" data-toggle="tab">
                                <i className="fa fa-picture-o"></i>&nbsp;
                                Imagens do imóvel
                            </a>
                        </li>
                    </ul>
                </div>
                <Icons data={this.props.data}/>
            </div>
        );
    }
}
export default Caracteristicas;