import React, { Component } from 'react';
import MapLocation from "./../../../components/MapLocation";
import { Circle, Map, TileLayer } from 'react-leaflet';
import Util from './../../../helpers/Util';

class Localizacao extends Component {
    constructor(props){
        super(props);
        this.state = {
            flagMap: false,               
        }
    }

    handleClick = () => {
        this.setState({
            flagMap: true
        })
    }

    handleMap = () => {
        let mapa; 
        if (this.state.flagMap) {
            mapa = ( this.props.finalidade === 'venda' ? this.getOpenStreetMap() : this.getGoogleMap() );
        } else {
            mapa = 
            <div className="content-map">
                <button onClick={this.handleClick} className="botao">Ver no mapa</button>
            </div>;
        }       

        return mapa;
    }

    getGoogleMap = () => (
        <MapLocation
            endereco={this.props.data.endereco}
            bairro={this.props.data.bairro}
            cidade={this.props.data.cidade}
            uf={this.props.data.estado}
            heigthDefault="350"
        />
    );

    getOpenStreetMap = () => (
        <Map center={[this.props.data.latitude, this.props.data.longitude]} zoom={16} scrollWheelZoom={false}>
            <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Circle center={[this.props.data.latitude, this.props.data.longitude]} fillColor="blue" radius={180} />
        </Map>
    );       

    render() {  
        return (
            <div className="block-map">
                <div className="detail-title">
                    <h2>Localização</h2>
                </div>
                <address>{this.props.data.bairro}, {Util.capitalize(this.props.data.cidade)} - {Util.capitalize(this.props.data.estado)}</address>
                {this.handleMap()}                                             
            </div>
        );
    }    
}
export default Localizacao;