import React from 'react';
import PropTypes from 'prop-types';

const RedeSocial = (props) => {
    return (
        <a href={props.to} target="_blank" rel="noopener noreferrer" >
            <i className={props.icon}></i>
        </a>
    );
}

RedeSocial.propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

export default RedeSocial;