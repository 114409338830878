import React from 'react';
import {Link} from 'react-router-dom';

const Header = () =>{
    return (
        <header className="header-agradecimento">
            <div className="container">
                <div className="logomarca">
                    <Link to="/">
                        <img src="images/logomarca/logomarca-horizontal.png" alt="Césa Rêgo Imóveis" />
                    </Link>    
                </div>   
            </div>
        </header>
    )
}
export default Header;