import React, {Component} from 'react';
import {HorizontalBar} from 'react-chartjs-2';

class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {
                labels: ['Apartamentos (19)', 'Casa (12)', 'Sala (3)', 'Loja (5)', 'Terrenos (3)', 'Vagas (4)'],
                datasets: [{
                    label: (props.data.finalidade === 'venda' ? 'Imóveis à venda' : 'Imóveis para alugar'),
                    data: [19, 12, 3, 5, 3, 4],
                    backgroundColor: [
                        'rgba(7, 88, 166, 0.5)',
                        'rgba(7, 88, 166, 0.5)',
                        'rgba(7, 88, 166, 0.5)',
                        'rgba(7, 88, 166, 0.5)',
                        'rgba(7, 88, 166, 0.5)',
                        'rgba(7, 88, 166, 0.5)'
                    ]
                }]
            }
        }      
    }
    render() {        
        return (
            <div className="chart">             
                <HorizontalBar width={300} height={200} data={this.state.data} />              
            </div>
        );
    }
}
export default Chart;