import React, { Component } from 'react';
import {Form, Input, Button} from "reactstrap";

class SearchCode extends Component {
    state = {
        finalidade: 'venda',
        codigo:''
    };

    handleSelect = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')
        });
    };

    handleOnChangeFinalidade = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            loading:true
        });
    };

    renderRedirect = (e) => {
        e.preventDefault();
        this.props.history.push('/detalhes/'+this.state.finalidade+'/'+this.state.codigo);
    };

    render() {
        return (
            <div id="search-code">
                <Form onSubmit={(e) => this.renderRedirect(e)}>
                    <div className="col2">
                        <Input
                            type="select"
                            name="finalidade"
                            onChange={(e) => this.handleOnChangeFinalidade(e)}
                            defaultValue={this.state.finalidade}>
                            <option value="venda">Comprar</option>
                            <option value="aluguel">Alugar</option>
                        </Input>
                    </div>
                    <div className="col8">
                        <Input type="text" name="codigo" onChange={(e) => this.handleSelect(e)} value={this.state.codigo} placeholder="Digite o código do imóvel" />
                    </div>
                    <div className="col2">
                        <Button className="botao"><i className="fa fa-search"></i> Buscar</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default SearchCode;