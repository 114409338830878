import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { withSwalInstance } from 'sweetalert2-react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import swal from 'sweetalert2';
import Services from './Services';

const estados = [
    "CE","AC","AL","AM","AP","BA","DF","ES","GO","MA","MG","MS","MT","PA",
    "PB","PE","PI","PR","RJ","RN","RO","RR","RS","SC","SE","SP","TO"
]

const numberMask = createNumberMask({
    prefix: 'R$ ',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true, 
    decimalSymbol: ','
  })

class Formulario extends Component {

    constructor(props){
        super(props);
        this._services = new Services(); 
        this.state = {
            nome: '',
            textMail: '',
            celular: '',
            whatsapp: '',
            idade: '',
            bairro: '',
            cidade: '',
            estado: 'CE',
            indicacao: '',
            veiculo: 'CARRO',
            pretensao_salarial: '',
            apiMessage: '' 
        }               
    }    

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearInputs = () => {        
        this.setState({
            nome: '',
            textMail: '',
            celular: '',
            whatsapp: '',
            idade: '',
            bairro: '',
            cidade: '',
            estado: 'CE',
            indicacao: '',
            veiculo: 'CARRO',
            pretensao_salarial: ''
            }
        );        
    }

    handleClick = (e) => {
        e.preventDefault();

        let dados = 
        {   
            "nome": this.state.nome,
            "email": this.state.textMail,
            "celular": this.state.celular,
            "whatsapp": this.state.whatsapp,
            "idade": this.state.idade,
            "bairro": this.state.bairro,
            "cidade": this.state.cidade,
            "estado": this.state.estado,
            "indicacao": this.state.indicacao,
            "veiculo": this.state.veiculo,
            "pretensao_salarial": this.state.pretensao_salarial
        }
        
        this._services.queroSerCorretor(dados)
            .then(api => {
                this.setState({
                    apiMessage: api.message,
                    show: true
                });
            });
    }

    render() {
        const SweetAlert = withSwalInstance(swal);

        return (
            <div className="col-form" id="realizar-cadastro">
                <h2>Envie suas informações e aguarde nosso contato</h2>
                <Form onSubmit={(e) => this.handleClick(e)}>                            
                    <div className="col-nome">
                        <FormGroup>
                            <Label for="nome">Nome</Label>
                            <Input type="text" name="nome" id="nome" required onChange={e => this.handleOnChange(e)} value={this.state.nome}/>
                        </FormGroup>                               
                    </div>
                    <div className="col-email">
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="text" name="textMail" id="email" required onChange={e => this.handleOnChange(e)} value={this.state.textMail}/>
                        </FormGroup>
                    </div>
                    <div className="col-celular">
                        <FormGroup>
                            <Label for="celular">Seu celular</Label>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                className="form-control"                        
                                guide={false}
                                id="celular"
                                name='celular'
                                required={true}
                                onChange={e => this.handleOnChange(e)}
                                value={this.state.celular}
                            />
                        </FormGroup>                              
                    </div>
                    <div className="col-celular">
                        <FormGroup>
                            <Label for="whatsapp">Seu Whatsapp</Label>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                className="form-control"                        
                                guide={false}
                                id="whatsapp"
                                name='whatsapp'
                                required={true}
                                onChange={e => this.handleOnChange(e)}
                                value={this.state.whatsapp}
                            />
                        </FormGroup>                                                                      
                    </div>                            
                    <div className="col-dt-nascimento">
                        <FormGroup>
                            <Label for="idade">Idade</Label>
                            <MaskedInput
                                mask={[/[1-9]/, /\d/, /\d/]}
                                className="form-control"                        
                                guide={false}
                                id="idade"
                                name='idade'
                                required={true}
                                onChange={e => this.handleOnChange(e)}
                                value={this.state.idade}
                            />
                        </FormGroup>                               
                    </div>
                    <div className="col-bairro">
                        <FormGroup>
                            <Label for="bairro">Bairro onde mora</Label>
                            <Input type="text" name="bairro" id="bairro" required onChange={e => this.handleOnChange(e)} value={this.state.bairro}/>
                        </FormGroup>                               
                    </div>
                    <div className="col-cidade">
                        <FormGroup>
                            <Label for="cidade">Cidade onde mora</Label>
                            <Input type="text" name="cidade" id="cidade" required onChange={e => this.handleOnChange(e)} value={this.state.cidade}/>
                        </FormGroup>                                
                    </div>
                    <div className="col-estado">
                        <FormGroup>
                            <Label for="estado">Estado</Label>
                            <Input type="select" name="estado" id="estado" required onChange={e => this.handleOnChange(e)} value={this.state.estado}>
                                {estados.map( UF => (
                                   <option key={UF} value={UF}>{UF}</option>      
                                ))}                                                              
                            </Input>
                        </FormGroup>                               
                    </div>                            
                    <div className="col-indicacao">
                        <FormGroup>
                            <Label for="indicacao">Foi indicado por alguém da César Rêgo?</Label>
                            <Input type="text" name="indicacao" id="indicacao" onChange={e => this.handleOnChange(e)} value={this.state.indicacao}/>
                        </FormGroup>                                
                    </div>
                    <div className="col-veiculo">
                        <FormGroup>
                            <Label for="veiculo">Possui veículo?</Label>
                            <Input type="select" name="veiculo" id="veiculo" onChange={e => this.handleOnChange(e)} value={this.state.veiculo}>
                                <option>Carro</option>
                                <option>Moto</option>
                                <option>Carro e moto</option>
                                <option>Não</option>
                            </Input>
                        </FormGroup>                                
                    </div>
                    <div className="col-pretensao-salarial">
                        <FormGroup>
                            <Label for="pretensao-salarial">Ganho pretendido</Label>
                            <MaskedInput
                                mask={numberMask}
                                className="form-control"                        
                                guide={false}
                                id="pretensao-salarial"
                                name='pretensao_salarial'
                                required={true}
                                onChange={e => this.handleOnChange(e)}
                                value={this.state.pretensao_salarial}
                            />                            
                        </FormGroup>                              
                    </div>
                    <div className="col-botao">
                        <Button type="submit" className="btn botao">Realizar cadastro</Button>
                    </div>    
                </Form>

                <SweetAlert
                    show={this.state.show}
                    text={this.state.apiMessage}
                    onConfirm={() => this.setState({ show: false }, () => this.clearInputs())}
                />                
            </div>
        );
    }
}
export default Formulario;