import React from 'react';
import Header from './Header';
import Vantagens from './Vantagens';
import Relacionamento from './Relacionamento';
import ComoDivulgar from './ComoDivulgar';
import Footer from './../../components/Layout/Footer/Footer';
import ScrollIntoView from './../../components/scrollIntoView';
import './cadastre-seu-imovel.css';

const CadastreSeuImovel = () => {
    return (
        <div>
            <ScrollIntoView>
            <Header/>
            <main>
                <Vantagens/>        
                <Relacionamento/>
                <ComoDivulgar/>   
            </main>
            <Footer/>
            </ScrollIntoView>
        </div>
    );
}
export default CadastreSeuImovel;