         
import React from 'react';
import Headline from '../../../components/HeadLine/HeadLine';
import CardPrecoMedio from './../../../components/Card/CardPrecoMedio/PrecoMedio';

const PrecoMedio = (props) => {
    return (
        <section>
            <div className="preco-medio">
                <div className="container">

                    <Headline label={"Preço médio dos apartamentos no bairro " + props.bairro}/>
                    <CardPrecoMedio data={props.venda}/>
                    <CardPrecoMedio data={props.aluguel}/> 
                                       
                </div>
            </div>
        </section>
    );
}
export default PrecoMedio;