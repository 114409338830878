import React from 'react';

const Features = (props) => {
    let preco, codigo;

    if (props.preco) {
        preco =
        <li>
            <span className="icon-card bg-icon_preco_imovel"></span>
            R$ {props.preco}
            <span> preço</span>
        </li> 
    }
    if (props.codigo) {
        codigo =
        <li>
            <span className="icon-card bg-icon_qrcode"></span>
            {props.codigo}
            <span> cód. do imóvel</span>
        </li> 
    }
    return (
        <ul>
            {preco}
            <li>
                {/* <img src="/images/icons/icon-planta-baixa.png" alt="Planta baixa" width="100%" height="auto"/> */}
                <span className="icon-card bg-icon_planta_baixa"></span>
                {props.area} m<sup>2</sup>
                <span> de área</span>
            </li>
            <li>
                <span className="icon-card bg-icon_quarto"></span>
                {props.quartos}
                <span> quartos</span>
            </li>
            <li>
                <span className="icon-card bg-icon_suite"></span>
                {props.suites}
                <span> suítes</span>
            </li>
            <li>
                <span className="icon-card bg-icon_banheiro"></span>
                {props.banheiros}
                <span> banheiros</span>
            </li>
            <li>
                <span className="icon-card bg-icon_garagem"></span>  
                {props.garagem}
                <span> vagas</span>
            </li>
            {codigo}
        </ul>
    );
}
export default Features;