import BaseServices from './../../helpers/BaseServices';
import { MODULE_ENDPOINT } from "./constants";

export default class Services extends BaseServices {
  constructor() {
    super(MODULE_ENDPOINT);
  };

  detalhesDoImovel = (finalidade, codigo) => {
    return fetch(`${MODULE_ENDPOINT}/${finalidade}/detalhes?codigo=${codigo}`, BaseServices._getRequestInfo())
      .then(response => {
        if (!response.ok) {
          throw response.json();
        }

        return response.json();
      });
  }

  solicitarInformacoesImovel = (dados) => {
    return fetch(`${MODULE_ENDPOINT}/email/solicitarInformacoesImovel`, BaseServices._getRequestInfo('POST', `${JSON.stringify(dados)}`))    
      .then(response => {
        if (!response.ok) {
          throw response.json();          
        }

        return response.json();
      });
  }
}