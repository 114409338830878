import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Header from  './Header';
import Footer from './../../../components/Layout/Footer/Footer';
import './agradecimento.css';

class Agradecimento extends Component {
    render() {
        return(
            <div>
                <Header />
                <main>
                    <div className="agradecimento">
                        <div className="container">
                            <div className="overlay"></div>            
                            <div className="wrap">                
                                <h1>Obrigado pelo seu contato!</h1>
                                <p>Em breve um de nossos colaboradores entrará em contato para mais detalhes.</p>                            
                            </div>    
                        </div>                    
                    </div>
                    <div className="link-imoveis-agradecimento">  
                        <h2>Fique à vontade para realizar novas buscas de imóveis de seu interesse</h2>  

                        <Link to={`/busca/venda/apartamento/fortaleza`} className="btn-secao-imoveis">                        
                            Ir para seção de Vendas
                        </Link>

                        <Link to={`/busca/aluguel/apartamento/fortaleza`} className="btn-secao-imoveis">                        
                            Ir para seção de Aluguel
                        </Link>
                    </div>
                </main>    
                <Footer />
            </div>
        );
    }
}

export default Agradecimento;