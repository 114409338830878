import React from 'react';
import {Form, FormGroup, Input, Button} from "reactstrap";
import './contato.css';

const Contato = (props) => {
    const text = (props.bairro ? `bairro ${props.bairro}` : `condomínio ${props.condominio}`);
    return (
        <section>
            <div className="form-contato" id="contato">
                <div className="container">
                    <div className="content">
                        <div className="overlay"></div>
                        <div className="wrap">
                            <h2>Só faça negócio no {text} depois de falar com a César Rêgo!</h2>
                            <h3>Somos especialistas em negociações no {text} e sempre encontramos as melhores condições.</h3>
                            <Form>
                                <div className="col">
                                    <FormGroup>
                                        <Input type="text" placeholder="Seu nome" />
                                    </FormGroup>                             
                                </div>
                                <div className="col">
                                    <FormGroup>
                                        <Input type="email" placeholder="Seu e-mail" />
                                    </FormGroup>                                
                                </div>
                                <div className="col">
                                    <FormGroup>
                                        <Input type="text" placeholder="Seu telefone" />
                                    </FormGroup>                                
                                </div>
                                <div className="col">
                                    <FormGroup>
                                        <Input type="select">
                                            <option>Selecione</option>
                                            <option value="">Quero vender ou alugar meu imóvel</option>
                                            <option value="">Quero vender meu imóvel</option>
                                            <option value="">Quero alugar meu imóvel</option>
                                        </Input>
                                    </FormGroup>                                    
                                </div>
                                <div className="col">
                                    <Button type="button" className="btn botao">Enviar contato</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Contato;