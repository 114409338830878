import React from 'react';
import Router from  './routers';
import './assets/css/style.css';
import MetaTags from './components/MetaTags';

function App() {
  return (
    <div className="App">
      <MetaTags 
        title='Imobili&aacute;ria em Fortaleza' 
        description='Casas e apartamentos para alugar e comprar em Fortaleza' 
        url={window.location.href}
        image=''
      />
      <Router />
    </div>
  );
}

export default App;
