import React from 'react';
import HeadLine from './../../../components/HeadLine/HeadLine';
import './ganhar-dinheiro.css';
import Item from './Item';

const Content = () => {
    return (
        <section>
            <div className="formas-de-ganhar-dinheiro">                
                <HeadLine label="Formas de ganhar dinheiro"/>
                <h3>Oferecemos a melhor plataforma para o corretor com diferentes possibilidades de renda</h3>
                
                <div className="container">
                    <div className="row">
                        <Item icon="comissao">Receba comissão por todas as suas vendas</Item>
                        <Item icon="captacao">Receba comissões quando suas captações de venda forem vendidas</Item>
                        <Item icon="indicacao">Seja bonificado quando um novo corretor indicado por você se associar à César Rêgo</Item>
                        <Item icon="mapa">Ganhe com a venda de imóveis e clientes indicados para colegas de outras regionais</Item>
                    </div>
                    <div className="row">
                        <Item icon="indicacao-clientes">Seja recompensado ao indicar clientes para aluguel</Item>
                        <Item icon="contrato">Seja bonificado quando suas captações para aluguel forem contratadas</Item>
                        <Item icon="visitas">Ganhe quando seu acompanhamento de visitas de aluguel fechar negócio</Item>
                        <Item icon="acordo">Ganhe quando os clientes indicados por você para parceiros da César Rêgo fecharem negócio</Item>
                    </div>    
                </div>
            </div>
        </section>
    );
}
export default Content;