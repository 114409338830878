import React from 'react';
import {Link} from "react-router-dom";
import './tag.css';
import PropTypes from 'prop-types';

const Tag = (props) => {
    return (
        <li className="list-label">
            <Link to={props.to} className="text">{props.text}</Link>
        </li>
    );
}

Tag.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export default Tag;

