export default class Services {

    getPosts = () => {
        return fetch('https://blog.cesarrego.com.br/wp-json/wp/v2/posts?_fields=images,title,excerpt,link')
            .then(response => {
                if (!response.ok) {
                    throw response.json();                    
                }
                return response.json();
            });
    }
}