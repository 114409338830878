import React from 'react';
import { Link } from 'react-router-dom';
import Formulario from './Formulario';

const Header = () => {
    return (
        <header>
            <div className="header-cadastre-seu-imovel">
                <div className="container">
                    <div className="overlay"></div>            
                    <div className="wrap">                
                        <div className="logomarca">
                            <Link to="/">
                                <img src="/images/logomarca/logomarca-horizontal.png" alt="Césa Rêgo Imóveis"/>
                            </Link>    
                        </div>
                        <div className="col">
                            <h1>
                            Tradição e inovação para conectar seu imóvel a clientes qualificados.
                            </h1>
                            <ul>
                                <li><i className="fa fa-check"></i> Anunciamos seu imóvel em várias plataformas e mídias digitais.</li>
                                <li>
                                    <i className="fa fa-check"></i> Somos especialistas em imóveis de alto padrão.
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    Temos corretores treinados e preparados para o melhor atendimento.
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    Parceria com escritórios de advocacia especializados na área imobiliária para maior segurança do vendedor e do comprador.
                                </li>
                            </ul>
                            <p className="auxiliar-text-button-whatsapp">Fale com a gente!</p>
                            <a href='https://api.whatsapp.com/send/?phone=558533053000&text&app_absent=0' target="_blank">
                                <div className="button-whatsapp-seu-imovel">
                                    <img src="/images/icons/icon-whatsapp2.png" alt="Logomarca César Rêgo imóveis"  width="10%" />
                                    <p>
                                        (85) 3305-3000
                                    </p>
                                </div>
                            </a>
                        </div>

                        <Formulario/>

                    </div>    
                </div>
            </div>    
        </header>
    );
}
export default Header;