import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Posts from "./Posts";

import './styles.css';

export default class Artigos extends Component {
    render() {
        return (
            <section>
                <div id="cesar-blog">
                    <div className="container">
                        <h2>
                            <Link
                                to={{pathname: "https://blog.cesarrego.com.br/"}}
                                rel="noopener noreferrer"
                                target="_blank"> 
                                Blog da César Rêgo
                            </Link>
                        </h2>
                        <div className="artigos-blog">
                            <Posts />
                        </div>
                    </div>
                </div>
            </section>
        );            
    }
}