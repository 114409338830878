import React from 'react';
import {Link} from 'react-router-dom';
import './cardLittle.css';

const CardLittle = (props) => {
    return (
        <div className="list-card">
            {props.cards.map((card, index) =>(
                <div key={index} className="item">
                    <Link to={card.slug}>
                        <div className="card-little">
                            <h2>{(card.totalBairros ? card.cidade + ' - ' + card.uf : card.bairro)}</h2>
                            <span><i className="fa fa-map-marker"></i> {(card.totalBairros ? card.totalBairros + ' bairros' : card.cidade)}</span>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
}
export default CardLittle;