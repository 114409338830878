import React from 'react';
import './headline.css';
import PropTypes from 'prop-types';

const Headline = (props) => {
    return (
        <div className="headline">
            <h2>{props.label}</h2>
        </div>
    );
}

Headline.propTypes = {
    label: PropTypes.string.isRequired
};

export default Headline;