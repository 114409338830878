import React from 'react';

const Highlight = (props) => {
    let destaque = '';
    let lancamento = '';

        if (props.destaque === true) {
            destaque = <div>
                            <span className="label-destaque">
                                <div className="fa fa-star"></div> Destaque
                            </span>
                            <span className="tarja"></span>
                       </div>;
        }

        if (props.lancamento === 'Lançamento') {
            lancamento = <div>
                            <span className="label-lancamento">
                                <div className="fa fa-diamond"></div> Lançamento
                            </span>
                            <span className="tarja-lancamento"></span>
                         </div>;
        }

    return ( 
        <div>{destaque}{lancamento}</div>
    );
}

export default Highlight;