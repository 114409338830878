import React, { Component } from 'react';
import Header from './Header';
import Vantagens from './Vantagens/Content';
import GanharDinheiro from './GanharDinheiro/Content';
import ParaSerCorretor from './ParaSerCorretor';
import Footer from './../../components/Layout/Footer/Footer';
import ScrollIntoView from './../../components/scrollIntoView';
import './quero-ser-corretor.css';

class QueroSerCorretor extends Component {
    render() {
        return (
            <ScrollIntoView>
                <div>
                    <Header/>
                    <main>
                        <Vantagens/>
                        <GanharDinheiro/>
                        <ParaSerCorretor/>      
                    </main>
                    <Footer/>
                </div>
            </ScrollIntoView>
        );
    }
}
export default QueroSerCorretor;